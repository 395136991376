import React, {useEffect, useState} from 'react';

 import * as VKID from '@vkid/sdk';

const AuthVk = () => {

    useEffect(() => {

        VKID.Config.set({
            // Идентификатор приложения.
            app: 51613374,
            // Адрес для перехода после авторизации
            redirectUrl: 'https://den-sushi.ru',
        });

        const oneTap = new VKID.OneTap();

        const container = document.getElementById('VkIdSdkOneTap');

        if (container) {
            oneTap.render({
                container,
            });
        }
    }, []);

    return (
         <div id='VkIdSdkOneTap'></div>
    )
};

export default AuthVk;
