import React, { useState, useEffect } from 'react';

const RandomNumber = () => {
  const [randomNumber, setRandomNumber] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const newRandomNumber = Math.floor(Math.random() * 10);
      setRandomNumber(newRandomNumber);
    }, 65); 

    return () => {
      clearInterval(intervalId); 
    };
  }, []);

  return (
    <span>
     {randomNumber}
    </span>
  );
};

export default RandomNumber;