import React from 'react';
import logo from '../../images/logo_white.svg';
import {useDispatch, useSelector} from "react-redux";
import {open_politika_modal, open_sber_modal} from "../../redux/auth-reducer";

const Footer = (props) => {
    let citys = useSelector(state => state.authPage.citys)
    let current_city_id = useSelector(state => state.authPage.currentCity);
    let sushi_day = useSelector(state => state.authPage.sushi_day);
    let sushi_month = useSelector(state => state.authPage.sushi_month);
    let sushi_year = useSelector(state => state.authPage.sushi_year);
    let city = citys[current_city_id];
    let dispatch = useDispatch();

    return (
        <section id="footer">
            <div className="container">
                <div className="menu_block">
                    <a href="/" className="logo"><img src={logo} /></a>
                    <div className="copyright">
                        2011—2024 © Фудзияма - ресторан доставки в {city.name_footer}<br />
                        Доставка суши на дом и в офис в {city.name_footer}
                    </div>
                    {city.phone && 
                        <ul className="links menu_links phone">
                            <li><a href={"tel:"+city.phone_call} className="phone">{city.phone}</a></li>
                        </ul>}
                    
                    {/*<ul className="links menu_links">
                        <li><a target="_blank" href="https://apps.apple.com/ru/app/%D1%84%D1%83%D0%B4%D0%B7%D0%B8%D1%8F%D0%BC%D0%B0/id993150106" className="button button_app_store"></a></li>
                        <li><a target="_blank" href="https://play.google.com/store/apps/details?id=com.riselogic.dm.sushifuji&hl=ru" className="button button_google_play"></a></li>
    </ul>*/}
                    <div className="clearfix"></div>
                    <ul className="links menu_links menu_links2 menu_links_foot">
                        <li><a className="popup-btn" onClick={()=> dispatch(open_politika_modal())}>Политика конфиденциальности</a></li>
                        <li><a className="popup-btn" onClick={()=> dispatch(open_sber_modal())} >Оплата заказа</a></li>
                    </ul>
                    <ul className="links menu_links menu_links_foot">
                        <li><div className="datetime">{sushi_day} {sushi_month} {sushi_year}</div></li>
                        <li><div className="city">{city.name}</div></li>
                        {city.vk_href ? <li><a href="https://vk.com/sushi102" target="_blank" className="vk"></a></li> : ''}
                        {city.inst_href ? <li><a href="https://www.instagram.com/sushifuji_ru" target="_blank" className="inst"></a></li> : ''}
                    </ul>
                </div>
            </div>
        </section>
    )
}

export default Footer;