import React from 'react';
import {useRouteMatch} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import RandomNumber from './RandomNumber';

const Random = (props) => {
     if(props.lotteryRandom){
        return (
            <section id="random">
                <div className="container position-relative">
                    <div className="content">
                        <h2 className="major">Определение победителей розыгрыша</h2>
                        <div className="marafon">
                            <div className="marafon_random">
                                <div className="random_case">
                                    <div id='random_one' className="random_box">
                                        {props.lotteryRandom.one ? <span className='random_win'>{props.lotteryRandom.one}</span>:<RandomNumber/>}
                                    </div>
                                    <div id='random_two' className="random_box">
                                        {props.lotteryRandom.two ? <span className='random_win'>{props.lotteryRandom.two}</span>:<RandomNumber/>}
                                    </div>
                                    <div id='random_three' className="random_box">
                                        {props.lotteryRandom.three ? <span className='random_win'>{props.lotteryRandom.three}</span>:<RandomNumber/>}
                                    </div>
                                    <div id='random_four' className="random_box">
                                        {props.lotteryRandom.four ? <span className='random_win'>{props.lotteryRandom.four}</span>:<RandomNumber/>}
                                    </div>
                                    <div id='random_five' className="random_box">
                                        {props.lotteryRandom.five ? <span className='random_win'>{props.lotteryRandom.five}</span>:<RandomNumber/>}
                                    </div>
                                    <div id='random_six' className="random_box">
                                        {props.lotteryRandom.six ? <span className='random_win'>{props.lotteryRandom.six}</span>:<RandomNumber/>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </section>
        )
    }
    
}
export default Random;