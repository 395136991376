import React from 'react';
import {Modal, Button} from 'react-bootstrap';
import {useDispatch, useSelector} from "react-redux";
import {
    close_roll_fourth,
    close_roll_third,
    close_roll_second,
    close_roll_first,
    close_soev_sous,
    close_vasabi,
    close_imbir,
    close_spais,
    close_unagi,
    close_oreh,
    close_cezar,
    close_firm,
    close_soul,
    close_pal,
    close_error,
    close_error_sterl,
    close_politika_modal,
    close_sber_modal
} from "../../redux/auth-reducer";
import roll_first from '../../images/roll_polniy_tunec.jpg';
import roll_second from '../../images/roll_losos_hot.jpg';
import roll_third from '../../images/roll_kaliforniya.jpg';
import roll_fourth from '../../images/roll_kentuki.jpg';
import soev_sous_image from '../../images/soev_sous.jpg'
import imbir_image from '../../images/imbir.jpg'
import vasabi_image from '../../images/vasabi.jpg'
import spais_image from '../../images/spais.jpg'
import unagi_image from '../../images/unagi.jpg'
import oreh_image from '../../images/sause_nut.jpg'
import cezar_image from '../../images/sause_mega_cezar.jpg'
import firm_image from '../../images/sause_firm.jpg'
import soul_image from '../../images/sause_soul.jpg'
import pal_image from '../../images/pal.jpg'
import cards from '../../images/cards_type_mini_short.png';
import  { useLocation } from "react-router-dom"

const Static_modals = (props) => {
    let dispatch = useDispatch();
    let roll_first_modal_show = useSelector(state => state.authPage.roll_first_modal_show);
    let roll_second_modal_show = useSelector(state => state.authPage.roll_second_modal_show);
    let roll_third_modal_show = useSelector(state => state.authPage.roll_third_modal_show);
    let roll_fourth_modal_show = useSelector(state => state.authPage.roll_fourth_modal_show);

    let soev_sous_show = useSelector(state => state.authPage.soev_sous_show);
    let imbir_show = useSelector(state => state.authPage.imbir_show);
    let vasabi_show = useSelector(state => state.authPage.vasabi_show);
    let spais_show = useSelector(state => state.authPage.spais_show);
    let unagi_show = useSelector(state => state.authPage.unagi_show);
    let oreh_show = useSelector(state => state.authPage.oreh_show);
    let cezar_show = useSelector(state => state.authPage.cezar_show);
    let firm_show = useSelector(state => state.authPage.firm_show);
    let soul_show = useSelector(state => state.authPage.soul_show);
    let pal_show = useSelector(state => state.authPage.pal_show);

    let error_modal_show = useSelector(state => state.authPage.error_modal_show);
    let error_modal_show_sterl = useSelector(state => state.authPage.error_modal_show_sterl);
    let sber_modal_show = useSelector(state => state.authPage.sber_modal_show);
    let politika_modal_show = useSelector(state => state.authPage.politika_modal_show);
    let citys = useSelector(state => state.authPage.citys)
    let current_city_id = useSelector(state => state.authPage.currentCity)
    let city = citys.hasOwnProperty(current_city_id) ? citys[current_city_id] : {};

    if(Object.keys(city).length == 0){return(<></>);}

    return (

        <>
            <Modal show={roll_first_modal_show} onHide={() => dispatch(close_roll_first())} dialogClassName="product_modal" centered>
                <div className="close_modal" onClick={() => dispatch(close_roll_first())}></div>
                <h3>Ролл Полный тунец 4 шт.</h3>
                <img src={roll_first} />
                <div className={'description'}>Слабосоленый тунец, лист салата, соус унаги, соус для запекания, сыр сливочный, кунжут</div>
                {/* <div className={'price'}>Цена в обычный день: <span>{city.roll_first_price}</span><span className={'r'}>₽</span></div> */}
            </Modal>
            <Modal show={error_modal_show} onHide={() => dispatch(close_error())} dialogClassName="error_modal" centered>
                <div className="close_modal" onClick={() => dispatch(close_error())}></div>
                <h3>Ошибка</h3>
                <div>К сожалению не удалось создать заказ, попробуйте немного позже или выберите другой ресторан!</div>
            </Modal>
            <Modal show={error_modal_show_sterl} onHide={() => dispatch(close_error_sterl())} dialogClassName="error_modal" centered>
                <div className="close_modal" onClick={() => dispatch(close_error_sterl())}></div>
                <h3>Оплата невозможна</h3>
                <div>К сожалению онлайн-заказ в данном ресторане сейчас недоступен.<br/>Вы можете сделать заказ придя непосредственно в ресторан.<br/>Приносим извинения за доставленные неудобства</div>
            </Modal>
            <Modal show={roll_second_modal_show} onHide={() => dispatch(close_roll_second())} dialogClassName="product_modal" centered>
                <div className="close_modal" onClick={() => dispatch(close_roll_second())}></div>
                <h3>Ролл Лосось хот 4 шт.</h3>
                <img src={roll_second} />
                <div className={'description'}>Слабосоленый лосось, сыр Cremette, огурцы, соус унаги, соус для запекания, кунжут белый</div>
                {/* <div className={'price'}>Цена в обычный день: <span>{city.roll_second_price}</span><span className={'r'}>₽</span></div> */}
            </Modal>
            <Modal show={roll_third_modal_show} onHide={() => dispatch(close_roll_third())} dialogClassName="product_modal" centered>
                <div className="close_modal" onClick={() => dispatch(close_roll_third())}></div>
                <h3>Ролл Калифорния 4 шт.</h3>
                <img src={roll_third} />
                <div className={'description'}>Суши рис, крем-краб, огурцы, сыр Cremette, икра масаго оранжевая, водоросли "Нори".</div>
                {/* <div className={'price'}>Цена в обычный день: <span>{city.roll_third_price}</span><span className={'r'}>₽</span></div> */}
            </Modal>
            <Modal show={roll_fourth_modal_show} onHide={() => dispatch(close_roll_fourth())} dialogClassName="product_modal" centered>
                <div className="close_modal" onClick={() => dispatch(close_roll_fourth())}></div>
                <h3>Ролл Кентукки 4 шт.</h3>
                <img src={roll_fourth} />
                <div className={'description'}>Суши рис, сыр Cremette, курица варено-копченая, огурцы, кунжут белый, водоросли "Нори".</div>
                {/* <div className={'price'}>Цена в обычный день: <span>{city.roll_fourth_price}</span><span className={'r'}>₽</span></div> */}
            </Modal>


            <Modal show={soev_sous_show} onHide={() => dispatch(close_soev_sous())} dialogClassName="product_modal" centered>
                <div className="close_modal" onClick={() => dispatch(close_soev_sous())}></div>
                <h3>Соевый соус</h3>
                <img src={soev_sous_image} />
                <div className={'description'}>Соевый соус (40гр.)</div>
                <div className={'price'}>Цена: <span>{city['dopProducts']['4b7d6daf-5354-4e9d-95f9-dd2cd11001c8']['price']}</span><span className={'r'}> ₽</span></div>
            </Modal>


            <Modal show={imbir_show} onHide={() => dispatch(close_imbir())} dialogClassName="product_modal" centered>
                <div className="close_modal" onClick={() => dispatch(close_imbir())}></div>
                <h3>Имбирь</h3>
                <img src={imbir_image} />
                <div className={'description'}>Имбирь маринованный (20гр.)</div>
                <div className={'price'}>Цена: <span>{city['dopProducts']['b4d4c85f-9eb6-4e09-b363-0042be254498']['price']}</span><span className={'r'}> ₽</span></div>
            </Modal>
            <Modal show={vasabi_show} onHide={() => dispatch(close_vasabi())} dialogClassName="product_modal" centered>
                <div className="close_modal" onClick={() => dispatch(close_vasabi())}></div>
                <h3>Васаби</h3>
                <img src={vasabi_image} />
                <div className={'description'}>Острая паста-приправа Васаби (6 гр.)</div>
                <div className={'price'}>Цена: <span>{city['dopProducts']['56f8c715-75fa-41fd-adc4-7559ddb8b409']['price']}</span><span className={'r'}> ₽</span></div>
            </Modal>

            <Modal show={spais_show} onHide={() => dispatch(close_spais())} dialogClassName="product_modal" centered>
                <div className="close_modal" onClick={() => dispatch(close_spais())}></div>
                <h3>Соус Спайс</h3>
                <img src={spais_image} />
                <div className={'description'}>Острый соус Спайс (40 гр.)</div>
                <div className={'price'}>Цена: <span>{city['dopProducts']['5ecde75d-21b2-44d9-a79b-1c3ae19bf14e']['price']}</span><span className={'r'}> ₽</span></div>
            </Modal>
            <Modal show={unagi_show} onHide={() => dispatch(close_unagi())} dialogClassName="product_modal" centered>
                <div className="close_modal" onClick={() => dispatch(close_unagi())}></div>
                <h3>Соус Унаги</h3>
                <img src={unagi_image} />
                <div className={'description'}>Соус Унаги (40 гр.)</div>
                <div className={'price'}>Цена: <span>{city['dopProducts']['264da2bd-1288-4a38-a72d-4a92879eeb20']['price']}</span><span className={'r'}> ₽</span></div>
            </Modal>
            <Modal show={oreh_show} onHide={() => dispatch(close_oreh())} dialogClassName="product_modal" centered>
                <div className="close_modal" onClick={() => dispatch(close_oreh())}></div>
                <h3>Ореховый Соус</h3>
                <img src={oreh_image} />
                <div className={'description'}>Ореховый Соус (30 гр.)</div>
                <div className={'price'}>Цена в обычный день: <span>{city['dopProducts']['c38f3430-a215-4c1f-8ace-193336f6203c']['price']}</span><span className={'r'}> ₽</span></div>
            </Modal>
            <Modal show={cezar_show} onHide={() => dispatch(close_cezar())} dialogClassName="product_modal" centered>
                <div className="close_modal" onClick={() => dispatch(close_cezar())}></div>
                <h3>Соус Мега Цезарь</h3>
                <img src={cezar_image} />
                <div className={'description'}>Соус Мега Цезарь (40 гр.)</div>
                <div className={'price'}>Цена в обычный день: <span>{city['dopProducts']['1c32135b-2aeb-4aa1-b391-dfa7a975fb00']['price']}</span><span className={'r'}> ₽</span></div>
            </Modal>
            <Modal show={firm_show} onHide={() => dispatch(close_firm())} dialogClassName="product_modal" centered>
                <div className="close_modal" onClick={() => dispatch(close_firm())}></div>
                <h3>Соус Фирменный</h3>
                <img src={firm_image} />
                <div className={'description'}>Соус Фирменный (40 гр.)</div>
                <div className={'price'}>Цена в обычный день: <span>{city['dopProducts']['fbfd3bd2-7fa2-4378-97e4-8e06341705d5']['price']}</span><span className={'r'}> ₽</span></div>
            </Modal>
            <Modal show={soul_show} onHide={() => dispatch(close_soul())} dialogClassName="product_modal" centered>
                <div className="close_modal" onClick={() => dispatch(close_soul())}></div>
                <h3>Соус Соул</h3>
                <img src={soul_image} />
                <div className={'description'}>Соус Соул (40 гр.)</div>
                <div className={'price'}>Цена в обычный день: <span>{city['dopProducts']['0694959e-8cca-4598-9512-233cd9142af8']['price']}</span><span className={'r'}> ₽</span></div>
            </Modal> 

             <Modal show={pal_show} onHide={() => dispatch(close_pal())} dialogClassName="product_modal" centered>
                <div className="close_modal" onClick={() => dispatch(close_pal())}></div>
                <h3>Палочки одноразовые</h3>
                <img src={pal_image} />
                <div className={'description'}></div>
                <div className={'price'}>Цена в обычный день: <span>{city['dopProducts']['dc718274-73f3-44fd-b3cd-5bb5fee579a4']['price']}</span><span className={'r'}> ₽</span></div>
            </Modal>


            <Modal show={sber_modal_show} onHide={() => dispatch(close_sber_modal())} dialogClassName="sber_modal" centered>
                <div className="close_modal" onClick={() => dispatch(close_sber_modal())}></div>
                <h3>Оплата заказа</h3>
                <div>
                    
                    {/* Для оплаты (ввода реквизитов Вашей карты) Вы будете перенаправлены на платёжный шлюз ПАО СБЕРБАНК. Соединение с платёжным шлюзом и передача информации осуществляется в защищённом режиме с использованием протокола шифрования SSL. В случае если Ваш банк поддерживает технологию безопасного проведения интернет-платежей Verified By Visa, MasterCard SecureCode, MIR Accept, J-Secure для проведения платежа также может потребоваться ввод специального пароля.<br/><br/> */}

                    {/* Настоящий сайт поддерживает 256-битное шифрование. Конфиденциальность сообщаемой персональной информации обеспечивается ПАО СБЕРБАНК. Введённая информация не будет предоставлена третьим лицам за исключением случаев, предусмотренных законодательством РФ.<br/>
                    Проведение платежей по банковским картам осуществляется в строгом соответствии с требованиями платёжных систем МИР, Visa Int., MasterCard Europe Sprl, JCB.<br/><br/> */}

                    После оплаты заказа отменить его нельзя.<br/><br/>

                    Реквизиты организаций, осуществляющих деятельность:<br/><br/>
                    1) г. Уфа, ул. Комсомольская, 15 (остановка «Фирма Мир», Центр). Деятельность осуществляет: ИП Ермакова Л.В., ИНН 056007822926, ОГРНИП 319715400000880<br/>
                    2) г. Уфа, ул. Гагарина, д. 10/2 (остановка «Натальи Ковшовой», Сипайлово). Деятельность осуществляет: ИП Ермакова Л.В., ИНН 056007822926, ОГРНИП 319715400000880<br/>
                    3) г. Уфа, ул. Бакалинская, 27 (ТКЦ «Ультра», 3 этаж, фуд-корт, Зеленая Роща). Деятельность осуществляет: ИП Мурзагильдина Л.А., ИНН 025606812500, ОГРНИП 321710000000015<br/>
                    4) г. Уфа, ул. Космонавтов, 8/1а (остановка "Космонавтов"). Деятельность осуществляет: ИП Мурзагильдина Л.А., ИНН 025606812500, ОГРНИП 321710000000015<br/>
                    5) г. Уфа, ул. Правды, 21 (остановка "Музей 112-й Башкирской кавдивизии", Дёма). Деятельность осуществляет: ИП Ермаков И.А., ИНН 056001679003, ОГРНИП 320028000041805<br/>
                    6) г. Уфа, ул. Первомайская, 50 (остановка "Универмаг Первомайский") Деятельность осуществляет: ИП Ермаков А.М., ИНН 056003295010, ОГРНИП 321710000008963<br/>
                    7) г. Уфа, ул. 50 лет СССР, 38 к.3 (остановка "Театр Нур") Деятельность осуществляет: ИП Кашапов А.Ф., ИНН 027601300999, ОГРНИП 322710000006052<br/>
                    8) г. Уфа, ул. 50 лет СССР, 2 (остановка "Дворец спорта") Деятельность осуществляет: ИП Ермаков А.М., ИНН 056003295010, ОГРНИП 321710000008963<br/>
                    9) г. Уфа, ул. Ферина, 16 (остановка "Богородско-Уфимский храм"). Деятельность осуществляет: ИП Шиц В.В., ИНН 026903517361, ОГРНИП 311026921000012<br/>
                    10) г. Уфа, ул. Транспортная, 44 (остановка "Бульвар Баландина"). Деятельность осуществляет: ИП Рау Р.О., ИНН 027717789289, ОГРНИП 322710000006290<br/>
                    11) г. Уфа, ул. Цюрупы, 91 (остановка "Центральный рынок"). Деятельность осуществляет: ИП Ермаков И.А., ИНН 056001679003, ОГРНИП 320028000041805<br/>
                    12) г. Уфа, ул. Орджоникидзе, 22 (остановка "Улица Суворова"). Деятельность осуществляет: ИП Ермаков А.М., ИНН 056003295010, ОГРНИП 321710000008963<br/>
                    13) г. Уфа, ул. Ахметова, 299 (остановка "Зареченский рынок"). Деятельность осуществляет: ИП Рау Р.О., ИНН 027717789289, ОГРНИП 322710000006290<br/>
                    14) г. Уфа, ул. Гагарина, 47/1 (остановка "ДЮЦ Салям"). Деятельность осуществляет: ИП Безверхова Г.Ф., ИНН 026905465662, ОГРНИП 322710000006259<br/>
                    15) г. Уфа, ул. Кольцевая, 177 (остановка "Магазин "Юбилейный"). Деятельность осуществляет: ИП Саитов А.В., ИНН 027611390092, ОГРНИП 321028000214721<br/>
                    16) г. Уфа, Проспект Октября, 68 (остановка "Юношеская библиотека"). Деятельность осуществляет: ИП Саитов А.В., ИНН 027611390092, ОГРНИП 321028000214721<br/>
                    17) г. Уфа, ул. Софьи Перовской, 19/1 (остановка "Юридический институт"). Деятельность осуществляет: ИП Кожевникова М.А., ИНН 027801480940, ОГРНИП 321028000212889<br/>
                    18) г. Уфа, Верхнеторговая площадь, 1 (остановка "Гостиный двор"). Деятельность осуществляет: ИП Саитов А.В., ИНН 027611390092, ОГРНИП 321028000214721<br/>
                    19) г. Уфа, Проспект Октября, 106. (остановка "Госцирк"). Деятельность осуществляет: ИП Ермаков А.М., ИНН 056003295010, ОГРНИП 321710000008963<br/>
                    20) г. Уфа, Проспект Октября, 135 (остановка "Городской дворец культуры"). Деятельность осуществляет: ИП Безверхова Г.Ф., ИНН 026905465662, ОГРНИП 322710000006259<br/>
                    21) г. Уфа, ул. Тальковая, 38 (пос. 8 марта, микрорайон "Сосны"). Деятельность осуществляет: ИП Кашапов А.Ф., ИНН 027601300999, ОГРНИП 322710000006052<br/>
                    22) г. Уфа, ул. Пархоменко, 156 (Ост. "Центр молодежи", ТЦ “Яй”). Деятельность осуществляет: ИП Кашапов А.Ф., ИНН 027601300999, ОГРНИП 322710000006052<br/>
                    23) г. Уфа, ул. Евгения Столярова, 3а (Остановка "Микрорайон Яркий"). Деятельность осуществляет: ИП Безверхова Г.Ф., ИНН 026905465662, ОГРНИП 322710000006259<br/>
                    24) г. Уфа, ул. Заозерная, 130/1 (Микрорайон “Нижегородка”). Деятельность осуществляет: ИП Кожевникова М.А., ИНН 027801480940, ОГРНИП 321028000212889
                    25) г. Уфа, ул. Рихарда Зорге, 12/2 (ТЦ “Галле”). Деятельность осуществляет: ИП Саитов А.В., ИНН 027611390092, ОГРНИП 321028000214721
                    26) г. Уфа, ул. Академика Сулейманова, 9 (Остановка "Цветы Башкирии"). Деятельность осуществляет: ИП Ганеева Д.Ф., ИНН 027414725487, ОГРНИП 323080000002232
                    27) г. Стерлитамак, Проспект Октября, 71. Деятельность осуществляет: ИП Вафин А.А., ИНН 027709132436, ОГРНИП 323028000254800<br/>
                    28) г. Стерлитамак, ул. Мира, 1Б. Деятельность осуществляет: ИП Ахметзагирова Э.М., ИНН 027614144313, ОГРНИП 321028000008073<br/>
                    29) г. Ижевск, ул. Пушкинская, 268г (Остановка "Международный Университет"). Деятельность осуществляет: ИП Рамазанова Я.Д., ИНН 027502410408, ОГРНИП 323080000010452<br/>
                    30) г. Ижевск,  ул. Автозаводская, 2 (Остановка "Ворошилова"). Деятельность осуществляет: ИП Рамазанова Я.Д., ИНН 027502410408, ОГРНИП 027502410408<br/>
                    31) г. Иглино, ул. Горького, 2/2 (ТЦ Вегас). Деятельность осуществляет: ИП Рау Р.О., ИНН 027717789289, ОГРНИП 322710000006290<br/>
                    32) г. Салават, бульвар Космонавтов, 13. Деятельность осуществляет: ИП Вафина Н.М., ИНН 027307581110, ОГРНИП 323028000226567<br/>
                    33) г. Нагаево, ул. Советская, 26/1 (Остановка "Нагаево"). Деятельность осуществляет: ИП Ганеева Д.Ф., ИНН 027414725487, ОГРНИП 323080000002232<br/>
                    34) г. Новый Уренгой, ул. Губкина, 19. Деятельность осуществляет: ИП Гришин Д.Г., ИНН 890417011300, ОГРНИП 320890100007664<br/>
                    35) г. Анапа,  Омелькова, 28. Деятельность осуществляет: ИП Наделяева Е.Ю., ИНН 032619764660, ОГРНИП 304032630000171<br/>
                    36) г. Новороссийск, Анапское шоссе, 4. Деятельность осуществляет: ИП Васильева И.Е., ИНН 741710127382, ОГРНИП 324745600076281<br/>
                    37) г. Темрюк, ул. Кирова, 1. Деятельность осуществляет: ИП Сафина Е.С., ИНН 590505884289, ОГРНИП 32323750030023<br/>
                    38) г. Кудрово, ул. Пражская, 3 (ост. Европейский проспект д.15). Деятельность осуществляет: ИП Третьяков Максим Артемович, ИНН 027306852872, ОГРНИП 322784700062837<br/>
                    39) г. Чебоксары, проспект 9 Пятилетки, 16/1. Деятельность осуществляет: ИП Рамазанова Я.Д., ИНН 027502410408, ОГРНИП 323080000010452<br/>
                    40) г. Чебоксары, Московский проспект, 37/1. Деятельность осуществляет: ИП Рамазанова Я.Д., ИНН 027502410408, ОГРНИП 323080000010452<br/>
                    41) г. Крымск, Синёва, 11. Деятельность осуществляет: ИП Багринец А.Е., ИНН 235307696328, ОГРНИП 324237500348290<br/>
                    42) г. Фролы, Сибирская, 28в. Деятельность осуществляет: ИП Шестакова Ю.Д., ИНН 591404697929, ОГРНИП 323595800147252<br/>
                    43) г. Пермь, ул. Карбышева, 41. Деятельность осуществляет: ИП Девятков А.А., ИНН 590505755886, ОГРНИП 320595800044185<br/>
                    44) г. Пермь, ул. Куйбышева, 80. Деятельность осуществляет: ИП Низовцев А. В., ИНН 590600170781, ОГРНИП 320595800007362<br/>
                    45) г. Пермь, ул. Маршала Рыбалко, 28. Деятельность осуществляет: ИП Низовцев Д.А., ИНН 590616171276, ОГРНИП 323595800094160<br/>
                    46) г. Пермь, ул. Уральская, 75. Деятельность осуществляет: ИП Низовцев Д.А., ИНН 590616171276, ОГРНИП 323595800094160<br/>
                </div>
            </Modal>
            <Modal show={politika_modal_show} onHide={() => dispatch(close_politika_modal())} dialogClassName="sber_modal" centered>
                <div className="close_modal" onClick={() => dispatch(close_politika_modal())}></div>
                <h3>Политика конфиденциальности</h3>
                <div>
                    Настоящая Политика конфиденциальности персональной информации (далее — Политика, для избежания разночтения под персональной информацией подразумевается и персональные данные) действует в отношении всей информации, которую Индивидуальный предприниматель Ермакова Лилия Вячеславовна (далее — ИП Ермакова Л.В) и/или его аффилированные лица, включая все лица, объединенные в рамках франчайзинговой сети под единой торговой маркой «Фудзияма» (далее - «Фудзияма»), могут получить о Пользователе (любое физическое лицо, предоставившее компании «Фудзияма» свои персональные данные посредством регистрации на сайте den-sushi.ru) во время использования им любого из сайтов, сервисов, служб и программ компании «Фудзияма» (далее — Сервисы). Согласие Пользователя на предоставление персональной информации, данное им в соответствии с настоящей Политикой в рамках отношений с одним из лиц, входящих в компанию «Фудзияма», распространяется на все лица, входящие в компанию «Фудзияма».<br/><br/>

                    Использование Сервисов означает безоговорочное согласие Пользователя с настоящей Политикой и указанными в ней условиями обработки его персональной информации; в случае несогласия с этими условиями Пользователь должен воздержаться от использования Сервисов.<br/><br/>

                    1.Персональная информация Пользователей, которую получает и обрабатывает компания «Фудзияма»<br/><br/>

                    1.1. В рамках настоящей Политики под «персональной информацией Пользователя» понимаются:<br/>
                    1.1.1. персональная информация, которую Пользователь предоставляет о себе самостоятельно при регистрации (создании учётной записи) или в процессе использования Сервисов, включая персональные данные Пользователя. Обязательная для предоставления Сервисов (оказания услуг) информация помечена специальным образом. Иная информация предоставляется Пользователем на его усмотрение.<br/>
                    1.1.2 данные, которые автоматически передаются Сервисам в процессе их использования с помощью установленного на устройстве Пользователя программного обеспечения, в том числе IP-адрес, информация из cookie, информация о браузере Пользователя (или иной программе, с помощью которой осуществляется доступ к Сервисам), время доступа, адрес запрашиваемой страницы.<br/>
                    1.2. Настоящая Политика применима только к Сервисам компании «Фудзияма». Компания «Фудзияма» не контролирует и не несет ответственность за сайты третьих лиц, на которые Пользователь может перейти по ссылкам, доступным на сайтах «Фудзияма». На таких сайтах у Пользователя может собираться или запрашиваться иная персональная информация, а также могут совершаться иные действия.<br/>
                    1.3. Компания «Фудзияма» в общем случае не проверяет достоверность персональной информации, предоставляемой Пользователями, и не осуществляет контроль за их дееспособностью. Однако компания «Фудзияма» исходит из того, что Пользователь предоставляет достоверную и достаточную персональную информацию по вопросам, предлагаемым в форме регистрации, и поддерживает эту информацию в актуальном состоянии. Последствия предоставления недостоверной информации определены в Публичной оферте (далее - оферта) расположенной на сайте www.sushifuji.ru.<br/><br/>


                    2. Цели сбора и обработки персональной информации Пользователей<br/>
                    2.1. Компания «Фудзияма» собирает и хранит только те персональные данные, которые необходимы для предоставления Сервисов и оказания услуг (исполнения оферты и/или иных договоров с Пользователем).<br/>
                    2.2. Персональную информацию Пользователя компания «Фудзияма» может использовать в следующих целях:<br/>
                    2.2.1. идентификация стороны в рамках оферты и договоров с компанией «Фудзияма»;<br/>
                    2.2.2. предоставление Пользователю персонализированных Сервисов;<br/>
                    2.2.3. связь с Пользователем, в том числе направление уведомлений, запросов и информации, касающихся использования Сервисов, оказания услуг, а также обработка запросов и заявок от Пользователя;<br/>
                    2.2.4. улучшение качества Сервисов, удобства их использования, разработка новых Сервисов и услуг;<br/>
                    2.2.5. рекламы своих продуктов и услуг;<br/>
                    2.2.6. проведение статистических и иных исследований на основе предоставленных данных.<br/><br/>

                    3. Условия обработки персональной информации Пользователя и её передачи третьим лицам<br/><br/>

                    3.1. Компания «Фудзияма» хранит персональную информацию Пользователей, обеспечивая их конфиденциальность и защиту от неправомерного или случайного доступа к ним третьих лиц.<br/>
                    3.2. Компания «Фудзияма» вправе передать персональную информацию Пользователя третьим лицам в следующих случаях:<br/>
                    3.2.1. передача необходима в рамках использования Пользователем определенного Сервиса, либо для оказания услуги Пользователю;<br/>
                    3.2.2. такая передача происходит в рамках продажи или иной передачи бизнеса (полностью или в части), при этом к приобретателю переходят все обязательства по соблюдению условий настоящей Политики применительно к полученной им персональной информации;<br/>
                    3.2.3. в целях обеспечения возможности защиты прав и законных интересов компании «Фудзияма» или третьих лиц в случаях, когда Пользователь нарушает оферту компании «Фудзияма».<br/>
                    3.4. При обработке персональных данных Пользователей компания «Фудзияма» руководствуется Федеральным законом РФ «О персональных данных» от 27.07.2006 г. №152-ФЗ.<br/><br/>

                    4. Меры, применяемые для защиты персональной информации Пользователей<br/>
                    Компания «Фудзияма» принимает необходимые и достаточные организационные и технические меры для защиты персональной информации Пользователя от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий с ней третьих лиц.<br/><br/>

                    5. Изменение Политики конфиденциальности<br/>
                    Компания «Фудзияма» имеет право вносить изменения в настоящую Политику. При внесении изменений в актуальной редакции указывается дата последнего обновления. Пользователь обязуется самостоятельно контролировать наличие изменений в настоящей Политике. Новая редакция Политики вступает в силу с момента ее размещения, если иное не предусмотрено новой редакцией Политики. Действующая редакция всегда находится на сайте den-sushi.ru.<br/><br/>

                    Дата публикации: 24.06.2021<br/><br/>

                    Уведомления, в случае внесения изменений в настоящую Политику, размещаются на сайте den-sushi.ru в виде информационного сообщения.
                </div>
            </Modal>
        </>
    )
}

export default Static_modals;