import $api from "./http";

export async function sendCode (phone, code) {
    return await $api.post('/auth', {
        phone: phone.replace(/[^0-9]/g, ''),
        code: code,
    }).then(response => response.data);
}

export async function sendSign (silentToken, uuid, name, photo) {
    return await $api.post('/auth', {
        silentToken: silentToken,
        uuid: uuid,
        name: name,
        photo: photo,
    }).then(response => response.data);
}

export async function authByCall (phone, cityId, name) {
    return await $api.post('/auth/call', {
        phone: phone.replace(/[^0-9]/g, ''),
        cityId: cityId,
        name: name,
    }).then(response => response.data);
}

export async function authBySms (phone, cityId, ignoreAlreadySend, name) {
    return await $api.post('/auth/sms', {
        phone: phone.replace(/[^0-9]/g, ''),
        cityId: cityId,
        ignoreAlreadySend: ignoreAlreadySend,
        isDaySushi: true,
        name: name
    }).then(response => response.data);
}