import React from 'react';
import {useRouteMatch} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

const Winners = (props) => {
  if(props.lotteryAllListWinners !== undefined) {
    if(props.lotteryAllListWinners.length > 0){
      return (
        <section id="winners">
          <div className="container position-relative">
            <div className="content">
              <div className="confetti">
                <div className="confetti-piece"></div>
                <div className="confetti-piece"></div>
                <div className="confetti-piece"></div>
                <div className="confetti-piece"></div>
                <div className="confetti-piece"></div>
                <div className="confetti-piece"></div>
                <div className="confetti-piece"></div>
                <div className="confetti-piece"></div>
                <div className="confetti-piece"></div>
                <div className="confetti-piece"></div>
                <div className="confetti-piece"></div>
                <div className="confetti-piece"></div>
                <div className="confetti-piece"></div>
              <div>
              <h2 className="major">Победители розыгрыша</h2>
              <table className="table">
                <thead>
                  <tr>
                    <th>Номер билета</th>
                    <th className="center">Имя победителя</th>
                    <th className="center">Номер телефона</th>
                    <th className="right">Приз</th>
                  </tr>
                </thead>  
                <tbody>
                {
                  props.lotteryAllListWinners.map((winner) => (
                    <tr key={winner.id}>
                      <td>{winner.id} </td>
                      <td className="center">{winner.name} </td>
                      <td className="center">+7******{winner.phone} </td>
                      <td className="right">{winner.win}</td>
                    </tr>
                  ))
                }
                </tbody>
              </table>
              </div>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </section>
      )
    }
  }
}
export default Winners;