import React from 'react';
import {Modal, Button} from 'react-bootstrap';
import {useDispatch, useSelector} from "react-redux";
import {change_city, cities_update_thunk, check_orders_thunk, close_citys_popup} from "../../redux/auth-reducer";
import {NavLink} from "react-router-dom";
const Citys_modal = (props) => {
    let dispatch = useDispatch();
    let citys_modal_show = useSelector(state => state.authPage.citys_modal_show);
    let citys =  useSelector(state => state.authPage.citys);

    if(Object.keys(citys).length <=1) return (<></>);

    return (
        <Modal show={citys_modal_show} onHide={() => dispatch(close_citys_popup())} dialogClassName="citys_modal" centered>
            <div className="close_modal" onClick={() => dispatch(close_citys_popup())}></div>
            <h3>Выберите город</h3>
            {
                Object.keys(citys).map(key => (
                    <NavLink style={{ order: citys[key]['order'] }} to={'/'+citys[key]['url']} className={'city_button'} key={'citys_modal_'+key} onClick={() => {dispatch(change_city(key));dispatch(cities_update_thunk());dispatch(check_orders_thunk());}}>{citys[key]['name']}</NavLink>
                ))
            }
        </Modal>
    )
}

export default Citys_modal;