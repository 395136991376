import axios from "axios";
import Fingerprint2 from "fingerprintjs2sync";

export const API_URL = 'https://api.sushifuji.ru/v1';
const $api = axios.create({
    withCredentials: false,
    baseURL: API_URL
});

/*$api.defaults.headers = {
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
    'Expires': '0',
};*/

let appdeviceid = localStorage.getItem('appdeviceid');
if(!appdeviceid){
    appdeviceid = (new Fingerprint2()).getSync().fprint;
    localStorage.setItem('appdeviceid', appdeviceid);
}

$api.interceptors.request.use((config) => {
    config.headers.Authorization = `Bearer bae7295d-df10-11eb-8d7a-fa163e6e6076`;
    config.headers.appplatformname = `Web`;
    config.headers.appplatformversion = `1`;
    config.headers.appversion = `1`;
    config.headers.appdeviceid = appdeviceid;
    config.headers['Cache-Control'] = 'no-cache';
    return config;
});

$api.interceptors.response.use((response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
},async function (error) {
    /*const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const access_token = await refreshAccessToken();
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
        return $api(originalRequest);
    }*/
    return Promise.reject(error);
});
export default $api;