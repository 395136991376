import React, {useEffect} from 'react';
import {useDispatch} from "react-redux";
import {change_password_timer, change_timer_to_start, sets_checker, cities_update_thunk, check_orders_thunk} from "../../redux/auth-reducer";

const Timer = (props) => {
  let dispatch = useDispatch();

  useEffect (()=>{
    let interval500 = setInterval(() => {dispatch(sets_checker());}, 500);//Изменение числа сетов},500);

    let interval1000 = setInterval(() => {
      dispatch(change_timer_to_start(-1));
      dispatch(change_password_timer());//Изменение таймера отправки смс
    },1000);

    dispatch(cities_update_thunk());
    let interval10000 = setInterval(() => {dispatch(cities_update_thunk());},10000);//Обновление информации по текущему городу

    dispatch(check_orders_thunk());
    let interval15000 = setInterval(() => {dispatch(check_orders_thunk());},15000);//Обновление информации по текущим заказам

    return ()  => {
      clearInterval(interval15000);
      clearInterval(interval10000);
      clearInterval(interval1000);
      clearInterval(interval500);
    }
  }, [])

  return (<></>)
}
export default Timer;