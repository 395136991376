import React from 'react';
import {connect} from "react-redux";
import Title from "./Title";
import {
    change_sets_count_new,
    decrease_sets,
    increase_sets,open_roll_fourth, open_roll_third, open_roll_second,
    open_citys_popup, open_roll_first
} from "../../redux/auth-reducer";

let mapStateToProps = (state) => {
    return {
        citys: state.authPage.citys,
        currentCityId: state.authPage.currentCity,
        setsCount: state.authPage.setsCount,
        lotteryAllListWinners: state.authPage.lotteryAllListWinners,
        lotteryRandom: state.authPage.lotteryRandom,
        sushi_day: state.authPage.sushi_day,
        sushi_month: state.authPage.sushi_month,
        setsInCart: state.authPage.setsInCart,
        orderResolution: state.authPage.orderResolution,
        orderIsWaitPaid: state.authPage.orderIsWaitPaid,
        orderIsReady: state.authPage.orderIsReady,
        orderIsClose: state.authPage.orderIsClose,
        countsAllClients: state.authPage.countsAllClients,
        countsReceivedClients: state.authPage.countsReceivedClients,
        name: state.authPage.name,
        orderNumber: state.authPage.orderNumber,
        queueList: state.authPage.queueList,
        orderNumberQueue: state.authPage.orderNumberQueue,
        orderAddress: state.authPage.orderAddress,
        lotteryList: state.authPage.lotteryList.join(', '),
        orderTimePeriod: state.authPage.orderTimePeriod,
        orderCountSets: state.authPage.orderCountSets,
        orderSum: state.authPage.orderSum

    }
}
const TitleContainer = connect(mapStateToProps, {
    change_sets_count_new,
    increase_sets,
    decrease_sets,
    open_citys_popup,
    open_roll_first,
    open_roll_second,
    open_roll_third,
    open_roll_fourth
})(Title);

export default TitleContainer;