import React, {useEffect, useState} from 'react';
import {Modal, Button} from 'react-bootstrap';
import $api from '../../http/index'
import InputMask from 'react-input-mask';
import PinInput from "react-pin-input";
import AuthVk from "./AuthVk";
import {authByCall, authBySms, sendCode} from "../../api";

const Auth_modal = (props) => {
    const [authType, setAuthType] = useState('');

    let Auth = (value = null) => {
        props.change_auth_info('');
        props.change_auth_error('');

        sendCode(
            props.phone,
            value
        ).then(response => {
            if (true === response.result) {
                localStorage.setItem('accessToken', response.accessToken);
                localStorage.setItem('refreshToken', response.refreshToken);
                props.auth_client(response.accessToken, response.refreshToken);
            } else {
                props.change_auth_error('Неправильный номер телефона или пароль');
            }
        });
    }


    let AuthStep = (ignoreAlreadySend = false, type='sms') => {
        setAuthType(type);
        props.change_auth_info('');
        props.change_auth_error('');
        props.change_auth_request_sending(true);

        if ('sms' === type) {
            authBySms(
                props.phone,
                props.currentCityId,
                ignoreAlreadySend,
                props.name
            ).then(response => {
                if(response.result === true) {
                    props.change_password_timer(response.timerRepeat);
                    props.change_auth_info('На номер '+props.phone+' отправлено SMS сообщение с паролем');
                } else if(response.errorCode === 'alreadySend'){
                    props.change_password_timer(response.timerRepeat);
                    props.change_auth_info('Код уже отправлялся вам ранее в SMS, проверьте сообщения от Fudziyama');
                } else if(response.errorCode === 'technicalWork'){
                    props.change_password_timer(response.timerRepeat);
                    props.change_auth_error('Ведутся технические работы. Попробуйте немного позже');
                } else if(response.errorCode === 'tryLater'){
                    props.change_password_timer(response.timerRepeat);
                    props.change_auth_error('Слишком частая отправка SMS. Попробуйте немного позже');
                } else if(response.errorCode === 'errorSend'){
                    props.change_password_timer(response.timerRepeat);
                    props.change_auth_error('Не удалось отправить. Попробуйте немного позже');
                } else if(response.data.errorCode === 'phoneIncorrect'){
                    props.change_password_timer(response.timerRepeat);
                    props.change_auth_error('Некорректный формат телефона');
                }
                props.change_auth_step(2);
                props.change_auth_request_sending(false);
            }).catch(function () {
                props.change_auth_request_sending(false);
            });
        }

        if ('phone' === type) {
            authByCall(
                props.phone,
                props.currentCityId,
                props.name
            ).then(response => {
                if(response.result === true) {
                    props.change_password_timer(response.timerRepeat);
                    props.change_auth_info('Ответьте на звонок, робот сообщит код доступа голосом');
                } else if(response.errorCode === 'technicalWork') {
                    props.change_password_timer(response.timerRepeat);
                    props.change_auth_error('Ведутся технические работы. Попробуйте немного позже');
                } else if(response.errorCode === 'tryLater') {
                    props.change_password_timer(response.timerRepeat);
                    props.change_auth_error('Слишком частые звонки. Попробуйте немного позже');
                } else if(response.errorCode === 'errorSend') {
                    props.change_password_timer(response.timerRepeat);
                    props.change_auth_error('Не удалось до вас дозвониться. Попробуйте немного позже');
                } else if(response.data.errorCode === 'phoneIncorrect') {
                    props.change_password_timer(response.timerRepeat);
                    props.change_auth_error('Некорректный формат телефона');
                }
                props.change_auth_step(2);
                props.change_auth_request_sending(false);
            }).catch(function () {
                props.change_auth_request_sending(false);
            });
        }
    }

    let AnotherPhone = () => {
        props.change_auth_step(1);
    }
    let SendSmsByEnter = (target) =>{
        if(target.charCode==13 && props.phone.indexOf('_') == -1 && props.phone.length == 17 && props.auth_request_sending === false){
            AuthStep(false, authType);
        }
    }

    const isPhoneValide = () => {
        return props.phone.indexOf('_') != -1 || props.phone.length != 17 || props.auth_request_sending ? true : false;
    }

    return (
        <>
            <Modal show={props.auth_modal_show} onHide={props.close_auth_modal} dialogClassName="auth_modal" centered>
                <div className="close_modal" onClick={props.close_auth_modal}></div>
                <h3>Войти на сайт</h3>
                {
                    props.authStep == 1
                        ?
                            <>
                                <div className="form_div">
                                    <label htmlFor="phone_number">Номер телефона:</label>
                                    <InputMask onKeyPress={SendSmsByEnter}
                                               mask="+7(999) 999-99-99"
                                               placeholder={'+7'}
                                               alwaysShowMask={false}
                                               maskChar="_"
                                               value={props.phone}
                                               onChange={(e) => props.change_auth_modal_phone(e.target.value)}/>
                                </div>

                                <button onClick={() => AuthStep(false, 'phone')}
                                        className='send_code'
                                        disabled={isPhoneValide()}>
                                    Подтвердить звонком
                                </button>

                                <button onClick={() => AuthStep(false, 'sms')}
                                        className='send_code'
                                        disabled={isPhoneValide()}>
                                    Выслать код по SMS
                                </button>

                                <AuthVk/>

                                <div className={'auth_mini_text'}>Продолжая, вы соглашаетесь со сбором и обработкой персональных данных</div>
                            </>
                        :
                            <>
                                <div className="form_div">
                                    <label htmlFor="password">Пароль</label>
                                    <PinInput
                                        length={'sms' === authType ? 5 : 4}
                                        onChange={(value, index) => {props.change_auth_modal_password(value)}}
                                        type="numeric"
                                        inputMode="number"
                                        inputStyle={{borderColor: 'rgba(135, 134, 134, 0.32)', height: '42px'}}
                                        inputFocusStyle={{borderColor: '#848484'}}
                                        onComplete={(value, index) => {Auth(value)}}
                                        autoSelect={true}
                                    />
                                </div>
                                {props.auth_info_block ? <div className="info_block">{props.auth_info_block}</div> : ''}
                                {props.auth_error_block ? <div className="error_block">{props.auth_error_block}</div> : ''}

                                <button className={'send_sms'}
                                        disabled={props.auth_request_sending || !props.canSendPassword}
                                        onClick={() => AuthStep(true, authType)}>
                                    {props.canSendPassword
                                        ? 'Повторно выслать код'
                                        : 'Отправить повторно через '+Math.floor(props.sendPasswordTimer/60).toString().padStart(2, '0')+':'+(props.sendPasswordTimer % 60).toString().padStart(2, '0')}
                                </button>
                                <button className="another_phone" onClick={AnotherPhone}>Указать другой телефон</button>
                            </>
                }
            </Modal>
        </>
    )
};

export default Auth_modal;