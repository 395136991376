import React from 'react';
import {useRouteMatch} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import HOW_1 from '../../images/how1.png';
import HOW_2 from '../../images/how2.png';
import HOW_3 from '../../images/how3.png';
import HOW_4 from '../../images/how4.png';
import HOW_5 from '../../images/how5.png';
import HOW_6 from '../../images/how6.png';

const Algoritm = () => {
  let dispatch = useDispatch();
  let canTryOrder = useSelector(state => state.authPage.canTryOrder);
  let citys = useSelector(state => state.authPage.citys)
  let current_city_id = useSelector(state => state.authPage.currentCity)
  let city = citys[current_city_id];

  return (
    <section id="three">
      <div className="container position-relative">

        <div className="content">
          <h2 className="major">Этапы участия</h2>


          <div className="how_container">
                <div className="how_box how_box-one">
                    <div className="how_box-image">
                        <div className="how_box-num">1</div>
                        <div className="how_box-circle">
                            <img src={HOW_1} alt="" loading="lazy"/>
                        </div>
                    </div>
                    <div className="how_box_text">
                        Добавьте набор за {city.new_price} ₽
                        (максимум {city.max_sets_by_user} набора
                        на одного участника)
                    </div>
                </div>
                <div className="how_box how_box-two">
                    <div className="how_box-image">
                        <div className="how_box-num">2</div>
                        <div className="how_box-circle">
                            <img src={HOW_3} alt="" loading="lazy"/>
                        </div>
                    </div>
                    <div className="how_box_text">
                        Добавьте соевый соус/имбирь/васаби
                        {/* <br/>
                        <span>до {city.lotterybefore} чтобы стать участником розыгрыша!</span> */}
                    </div>
                </div>
                <div className="how_box how_box-four">
                    <div className="how_box-image">
                        <div className="how_box-num">3</div>
                        <div className="how_box-circle">
                            <img src={HOW_4} alt="" loading="lazy"/>
                        </div>
                    </div>
                    <div className="how_box_text">
                        Выберите удобный ресторан для самовывоза и оплатите
                        ваш заказ <b>онлайн</b>
                    </div>
                </div>
                <div className="how_box how_box-five">
                   <div className="how_box-image">
                        <div className="how_box-num">4</div>
                        <div className="how_box-circle">
                            <img src={HOW_5} alt="" loading="lazy"/>
                        </div>
                    </div>
                    <div className="how_box_text">
                        Ожидайте свою очередь
                        и СМС-оповещение о готовности вашего заказа
                    </div>
                </div>
                <div className="how_box how_box-five">
                   <div className="how_box-image">
                        <div className="how_box-num">5</div>
                        <div className="how_box-circle">
                            <img src={HOW_2} alt="" loading="lazy"/>
                        </div>
                    </div>
                    <div className="how_box_text">
                    Заберите ваш заказ из ресторана {city.address}
                    <br/>
                    <span>Розыгрыш призов пройдет в прямом эфире сегодня после {city.lotteryafter}</span>
                    </div>
                </div>
            </div>



          
          <div className="clearfix"></div>
        </div>
      </div>
    </section>
  )
}

export default Algoritm;