import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";

const Loader = ({color, width, height, style}) => {
    let isLoading = useSelector(state => state.authPage.is_loading);

    if (isLoading) {
        return (
            <svg style={style} width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="wait_animation">
                <path d="M16.5031 20.9343C16.999 21.9182 18.2117 22.3262 19.0999 21.6743C20.7217 20.4841 22.0273 18.8983 22.8836 17.0544C24.0217 14.6039 24.2994 11.841 23.6719 9.21295C23.0443 6.58492 21.5483 4.24558 19.4258 2.57359C17.3034 0.901598 14.6788 -0.00518446 11.9769 2.22814e-05C9.27497 0.00522903 6.65389 0.92212 4.53791 2.60228C2.42192 4.28244 0.934881 6.62753 0.317484 9.25795C-0.299913 11.8884 -0.011529 14.6502 1.13596 17.0963C1.99935 18.9368 3.31108 20.5176 4.93744 21.7016C5.82819 22.35 7.03923 21.9374 7.53133 20.9516V20.9516C8.02344 19.9658 7.60026 18.7836 6.7662 18.0637C5.91947 17.3329 5.22948 16.4278 4.74819 15.4018C3.98224 13.769 3.78974 11.9255 4.20186 10.1697C4.61397 8.41385 5.60658 6.84848 7.01901 5.72697C8.43144 4.60545 10.181 3.99343 11.9846 3.98995C13.7881 3.98647 15.54 4.59176 16.9568 5.70782C18.3735 6.82388 19.3722 8.38541 19.791 10.1396C20.2099 11.8938 20.0245 13.7381 19.2649 15.3739C18.7875 16.4017 18.101 17.3094 17.2571 18.0435C16.4259 18.7666 16.0072 19.9505 16.5031 20.9343V20.9343Z" fill={color}>
                </path>
            </svg>
        )
    } else {
        return '';
    }
}

export default Loader;