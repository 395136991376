import React, {useEffect} from 'react';
import Flip from '../../Flip';
import { Link, Button, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import QRCode from 'react-qr-code';
import {useDispatch, useSelector} from "react-redux";
import {
    change_auth_modal_phone,
    change_user_name, changeIsLoading,
    decrease_dops,
    decrease_sets,
    get_actual_restaurants, increase_dops,
    increase_sets, nextPage, open_auth_modal,
    open_order
} from "../../redux/auth-reducer";
import InputMask from "react-input-mask";
import Loader from "../Loader/Loader";

const Title = (props) => {
    let dispatch = useDispatch();

    let now_city = props.citys[props.currentCityId];
    let d = Math.floor(now_city.seconds_to_start / (3600 * 24));
    let h = Math.floor(now_city.seconds_to_start % (3600 * 24) / 3600);
    let m = Math.floor(now_city.seconds_to_start % 3600 / 60);
    let s = Math.floor(now_city.seconds_to_start % 3600 % 60);
    
    useEffect(() => {
        if (now_city.seconds_to_start === -1) {
            dispatch(changeIsLoading(true));
        }

        if (now_city.seconds_to_start > 0 || now_city.seconds_to_start == 0) {
            dispatch(changeIsLoading(false));
        }

    }, [now_city.seconds_to_start])
    return (
        <section id="title"  className={props.orderResolution ? 'banner_step_2' : 'banner_step_1'}>
            <h1>День суши</h1>
            <h2></h2>
            <h3>Скидка {Math.floor((1 - (now_city.new_price / now_city.old_price)) * 100)}% на набор</h3>
            <div className="timer_text">

                {
                    <Loader color={'#FFFFFF'} width={120} height={120} style={{marginBottom: '10px'}}/>
                }

                {
                    now_city.seconds_to_start === -1 ? '' :
                        now_city.seconds_to_start > 0
                            ? <div className="count_sets">
                                <div className="time_text">до дня суши в Фудзияме осталось</div>
                                <div className="countdown">
                                <div className="countdown-number">
                                    <span className="days countdown-time">{d.toString().padStart(2, '0')}</span>
                                    <span className="countdown-text">дней</span>
                                </div>
                                <div className="countdown-number">
                                    <span className="days countdown-time">{h.toString().padStart(2, '0')}</span>
                                    <span className="countdown-text">часов</span>
                                </div>
                                <div className="countdown-number">
                                    <span className="days countdown-time">{m.toString().padStart(2, '0')}</span>
                                    <span className="countdown-text">минут</span>
                                </div>
                                <div className="countdown-number">
                                    <span className="days countdown-time">{s.toString().padStart(2, '0')}</span>
                                    <span className="countdown-text">секунд</span>
                                </div>
                                </div>
                                <div className="time_text"></div>
                            </div>
                            : (props.setsCount == 0 ?
                                <h1 className="end_sets">Наборы <span>закончились</span></h1>
                                : props.setsCount != 99999
                                    ?
                                    <div className="count_sets">
                                        <div className={'text_2'}>
                                            {props.countsAllClients ? <>у нас уже <span className={'black'}>{props.countsAllClients}</span><br /></> : ''}
                                            {props.countsReceivedClients ? <><span className={'red'}>{props.countsReceivedClients}</span> свой заказ</> : ''}
                                        </div>
                                        <div className="time_text">до окончания дня суши осталось</div>

                                        <div className="count_limits">{props.setsCount}</div>

                                        <div className="time_text">наборов</div>
                                        {now_city.sets_buy > 0 ? <div className="sets_buying">куплено {now_city.sets_buy} шт. наборов.</div> : ''}
                                    </div>
                                    : ''
                            )

                }
            </div>
            <div className="title_imgs">
            {/* <span className="title_img title_img-one"></span> */}
                <span className="title_img title_img-two"></span>
                <span className="title_img title_img-three"></span>
                <span className="title_img title_img-four"></span>
                {/* <span className="title_img title_img-five"></span> */}
                {/* <span className="title_img title_img-six"></span> */}
                {/* <span className="title_img title_img-seven"></span> */}
                <span className="title_img title_img-eight"></span>
                <span className="title_img title_img-nine"></span>
                {/* <span className="title_img title_img-ten"></span> */}
            </div>
          
        </section>
    )
};


export default Title;