import React from 'react';
import {
    close_auth_modal,
    change_auth_modal_phone,
    change_auth_modal_password,
    auth_client,
    change_error_login_text,
    change_password_timer,
    change_password_flag,
    change_auth_step,
    change_auth_info, change_auth_error, change_auth_request_sending
} from "../../redux/auth-reducer";
import {connect} from "react-redux";
import Auth_modal from './Auth_modal';

let mapStateToProps = (state) => {
    return {
        auth_modal_show: state.authPage.auth_modal_show,
        phone: state.authPage.phone,
        password: state.authPage.password,
        isAuth: state.authPage.isAuth,
        name: state.authPage.name,
        error_login_text: state.authPage.error_login_text,
        canSendPassword: state.authPage.canSendPassword,
        sendPasswordTimer: state.authPage.sendPasswordTimer,
        authStep: state.authPage.authStep,
        currentCityId: state.authPage.currentCity,
        auth_info_block: state.authPage.auth_info_block,
        auth_error_block: state.authPage.auth_error_block,
        auth_request_sending: state.authPage.auth_request_sending,
    }
}

const Auth_modalContainer = connect(mapStateToProps, {
    close_auth_modal,
    change_auth_modal_phone,
    change_auth_modal_password,
    auth_client,
    change_error_login_text,
    change_password_timer,
    change_password_flag,
    change_auth_step,
    change_auth_info,
    change_auth_error,
    change_auth_request_sending
})(Auth_modal);

export default Auth_modalContainer;